import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-upload-summary',
  templateUrl: './upload-summary.component.html',
  styleUrls: ['./upload-summary.component.scss']
})
export class UploadSummaryComponent implements OnInit {

  @Input() invDetails;

  constructor() {
  }

  ngOnInit() {
    // this.invDetails.min_price = (parseInt(this.invDetails.min_price) / 100000).toFixed(2);
    // this.invDetails.max_price = (parseInt(this.invDetails.max_price) / 100000).toFixed(2);

  }

}
