import { Action, InventoryListActionTypes } from '../actions/inventory-list.action';

export interface InventoryListState {
  invSortFilter: any;
  archSortFilter: any;
}

const initialState: InventoryListState = {
  invSortFilter: null,
  archSortFilter: null
};


export function inventoryListReducer(state = initialState, action: Action) {
  switch (action.type) {
    case InventoryListActionTypes.ADD_SORT_FILTER:
      return { ...state, invSortFilter: action.payload };

    case InventoryListActionTypes.REMOVE_SORT_FILTER:
      return { ...state, invSortFilter: null };

    default: return state;
  }
}

export function archiveListReducer(state = initialState, action: Action) {
  switch (action.typeArch) {
    case InventoryListActionTypes.ADD_ARCH_SORT_FILTER:
      return { ...state, archSortFilter: action.payload };

    case InventoryListActionTypes.REMOVE_ARCH_SORT_FILTER:
      return { ...state, archSortFilter: null };

    default: return state;
  }
}
