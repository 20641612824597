

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

export interface User {
  token: string
  redirectTrigger: boolean
  dealer_id: any
  refresh: string
  edsp_id: any

}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {


  API_URL = environment.api;
  thirdPartyUser: { token: string, secret: string } | undefined
  currentUser: User
  userType:string
  constructor(private http: HttpClient) {
    this.userType = JSON.parse(localStorage.getItem('user_type'))
    if(this.userType == 'dealer'){
      this.currentUser = {
        token: JSON.parse(localStorage.getItem('token')),
        redirectTrigger: JSON.parse(localStorage.getItem('redirectTrigger')),
        dealer_id: JSON.parse(localStorage.getItem('dealer_Id')),
        refresh: JSON.parse(localStorage.getItem('refresh')),
        edsp_id: JSON.parse(localStorage.getItem('edsp_id'))
      }
    }else if(this.userType == 'TETM' || this.userType == 'HO'){
      this.currentUser = {
        token: JSON.parse(sessionStorage.getItem('token')),
        redirectTrigger: JSON.parse(sessionStorage.getItem('redirectTrigger')),
        dealer_id: JSON.parse(sessionStorage.getItem('dealer_Id')),
        refresh: JSON.parse(sessionStorage.getItem('refresh')),
        edsp_id: JSON.parse(sessionStorage.getItem('edsp_id'))
      }
    }
   

  }


  setThirdPartyUser(tUser: { token: string, secret: string }) {
    this.thirdPartyUser = tUser;
  }



  removeAdditionalDetails() {
    localStorage.setItem('redirectTrigger', "false");
    this.currentUser = { ...this.currentUser, redirectTrigger: false };
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('redirectTrigger');
    localStorage.removeItem('dealer_Id');
    localStorage.removeItem('refresh');
    localStorage.removeItem('edsp_id');
    localStorage.removeItem('browser_id');
    localStorage.removeItem('user_type')
    let url = "https://tractorbazar.com/";
    window.location.href = url;

  }

  public isAuthenticated(): boolean {
    var token , dealer_id
    if(this.userType == 'dealer'){
       token = localStorage.getItem('token');
       dealer_id = localStorage.getItem('dealer_Id');
    }else if(this.userType == 'HO' || this.userType == 'TETM'){
      token = sessionStorage.getItem('token');
      dealer_id = sessionStorage.getItem('dealer_Id');
    }
    
    // Check whether the token is expired and return
    // true or false
    return !!(token && dealer_id);

  }



  getRefreshToken(a: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let body = `refresh=${a}`;
    const options = {
      headers
    };

    return this.http.post<any>(`${this.API_URL}/user/refresh-token`, body, options)
      .pipe(map(response => {
        let newAccess = response.access;
        localStorage.setItem('token', JSON.stringify(newAccess));

        this.currentUser = { ...this.currentUser, token: newAccess }
        return newAccess;
      }), catchError(error => {
        throw error
      }));

  }
}
