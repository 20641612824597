import { Action } from "@ngrx/store";

export enum LeadsListActionTypes {
    ADD_SORT_FILTER = '[LEADS] Add Sort Filter',
    REMOVE_SORT_FILTER = '[LEADS] Remove Sort Filter'
}

export class LeadsAddSortFilter implements Action {
    readonly type = LeadsListActionTypes.ADD_SORT_FILTER;
    constructor(public payload: any) { }
}

export class LeadsRemoveSortFilter implements Action {

    readonly type = LeadsListActionTypes.REMOVE_SORT_FILTER;
    constructor() { }
}

export type Action = LeadsRemoveSortFilter | LeadsAddSortFilter;
