import { Component, OnInit } from '@angular/core';
import { recommendationsConfig } from './recommendations.config';
import { Location } from '@angular/common';
@Component({
  selector: 'dealer-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss']
})
export class RecommendationsComponent implements OnInit {
  //recommendations = recommendationsConfig;
  constructor(private location: Location) { }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

}
