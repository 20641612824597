import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class LeadsSearchFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      if (!(it.tractor_model === null)) {
        return (it.tractor_model.toLowerCase().includes(searchText)) || (it.first_name.toLowerCase().includes(searchText));
      }
    });
  }
}
