import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dealer-tractor-info',
  templateUrl: './tractor-info.component.html',
  styleUrls: ['./tractor-info.component.scss']
})
export class TractorInfoComponent implements OnInit {



  @Input() userLead;

  constructor() {

  }

  ngOnInit() {
  }

}
