export const environment = {

  production: false,
  // api: 'https://qatafeapi.siplsolutions.com'
  // api: 'https://tafe-dev.poc.hashedin.com'
  api: 'https://qa01.tractorbazar.com'
  // api: 'https://m01.tractorbazar.com'
  //api: 'http://192.168.0.35:8001'

  ,
  firebase: {
    apiKey: "AIzaSyAMWrSlddzIERH-gkXPjsCYbG93jIkukw8",
    authDomain: "tractorbazar-notifications.firebaseapp.com",
    databaseURL: "https://tractorbazar-notifications.firebaseio.com",
    projectId: "tractorbazar-notifications",
    storageBucket: "tractorbazar-notifications.appspot.com",
    messagingSenderId: "710862786122",
    appId: "1:710862786122:web:c96b1f896b77581c58e0b3"
  }

};
