import { Component, Input } from '@angular/core';

@Component({
  selector: 'dealer-data-tractor-card',
  templateUrl: './data-tractor-card.component.html',
  styleUrls: ['./data-tractor-card.component.scss']
})
export class DataTractorCardComponent {

  imgUrl: any;
  tractorModel: any;
  purchases: any;
  to: any;
  ago: any;

  @Input() tractor: any;

  constructor() {

  }



}
