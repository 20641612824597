import { Action } from "@ngrx/store";

export enum DatabaseListActionTypes {
    ADD_SORT_FILTER = '[DATABASE] Add Sort Filter',
    REMOVE_SORT_FILTER = '[DATABASE] Remove Sort Filter'
}

export class DatabaseAddSortFilter implements Action {
    readonly type = DatabaseListActionTypes.ADD_SORT_FILTER;
    constructor(public payload: any) { }
}

export class DatabaseRemoveSortFilter implements Action {

    readonly type = DatabaseListActionTypes.REMOVE_SORT_FILTER;
    constructor() { }
}

export type Action = DatabaseRemoveSortFilter | DatabaseAddSortFilter;
