import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dealer-buyer-view-box',
  templateUrl: './buyer-view-box.component.html',
  styleUrls: ['./buyer-view-box.component.scss']
})
export class BuyerViewBoxComponent implements OnInit {
  @Input() inventory: any;
  @Input() secondaryContact : any;
  disableButton : boolean = true;  
  imgUrl : any;


  constructor( ) { }

 
  ngOnInit() {
    
    if (Array.isArray(this.inventory.assets)) {
      if (this.inventory.assets.length > 0) {
        let left = this.inventory.assets.filter(obj => {
          return obj.type === "left"
        })
        if (left.length > 0) { 
          this.imgUrl = left[0].url 

        }
        else {
          if (!(this.inventory.assets[0].type === "video")) {
            this.imgUrl = this.inventory.assets[0].url

          }
          else {
            this.imgUrl = "assets/background.png";

          }

        }
      }
      else {

        this.imgUrl = "assets/background.png";

      }
    }
    else {
      this.imgUrl = "assets/background.png";
    }
  }

}