import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  private snackbarSubject = new Subject<any>();
  public snackbarState = this.snackbarSubject.asObservable();

  constructor() { }

  show(messageHead: string, message: string, type?: string) {

    this.snackbarSubject.next(
      {
        show: true,
        messageHead,
        message,
        type
      }
    );

  }



  // to use snackbar
  //   public snackbarService: SnackbarService
  //
  //   messageBox() {
  //     this.snackbarService.show('Successful','Message here');
  //   }
  //
  //   messageBox2() {
  //     this.snackbarService.show('Unsuccessful','Error occures', 'danger');
  //   }

}
