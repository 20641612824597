import { Component, OnInit, Input} from '@angular/core';
import { LanguageService } from "../../services/language.service";
@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss']
})
export class UserDataComponent implements OnInit {
@Input() user:any;
  constructor() { }

  ngOnInit() {
  }
  

  public myFun(event){
   // console.log(event)
  }

}
