import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { monthNames } from '../../config/common.config';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'dealer-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent {

  @Output() notificationClick = new EventEmitter();
  @Input() notification: any;
  @Input() newsfeed: boolean;

  type: string;
  location: string;
  priceRange: string;
  date: number;
  month: string;
  status: string;
  tier: string;
  is_read: boolean = false;
  ntfctnData: any;
  ntfctnId: number;
  adminNotification: string;
  callbackRequestMessage:string;
  constructor(private router: Router,
    private notificatioService: NotificationService
  ) { }

  ngOnChanges() {
    this.ntfctnData = this.notification.notification_data;

    //get   location , price , dates
    this.ntfctnId = this.notification.id;
    this.location = `${this.ntfctnData.dealer_district}, ${this.ntfctnData.dealer_state}`;
    this.is_read = this.notification.is_read;

    this.type = this.ntfctnData.type;
    let dateString = new Date(this.ntfctnData.date);
    this.date = dateString.getDate();

    let monthNumber = dateString.getMonth();
    this.month = monthNames[monthNumber];


    switch (this.ntfctnData.type) {
      case 'inventory_status_change':
        this.status = this.ntfctnData.status;
        break;
      case 'admin_notification':
        this.adminNotification = this.ntfctnData.message;
        break;
      case 'callback_request':
        console.log('data',this.ntfctnData)
        if(this.ntfctnData.user_type == 'aggregator')
        this.callbackRequestMessage = `${this.ntfctnData.name}, ${this.ntfctnData.saathi_type} (${this.ntfctnData.phone_number}) from ${this.ntfctnData.state},${this.ntfctnData.district} is requesting you to call back regarding ${this.ntfctnData.make} ${this.ntfctnData.model} (${this.ntfctnData.manufacture_year})`
        else if(this.ntfctnData.user_type == 'customer')
        this.callbackRequestMessage = `${this.ntfctnData.name} (${this.ntfctnData.phone_number}) from ${this.ntfctnData.state},${this.ntfctnData.district} is requesting you to call back regarding enquiry of ${this.ntfctnData.make} ${this.ntfctnData.model}(${this.ntfctnData.manufacture_year})`
      case 'lead':
        this.location = `${this.ntfctnData.district}, ${this.ntfctnData.state}`;
        if (this.ntfctnData.user_type == 'aggregator') {
          this.tier = this.ntfctnData.saathi_type;
        }
        break;
      default:
        break;
    }
  }

  cardClicked() {
    if (!this.is_read) {
      this.notificatioService.markNotificationRead(this.ntfctnId).subscribe(res => {
        this.is_read = true;
      });
    }
    switch (this.type) {
      
      case 'inventory_status_change':
        this.router.navigateByUrl(`/inventory/inventory-details/${this.ntfctnData.inventory_id}`);
        break;

      case 'old_inventory_intimation':
        this.router.navigateByUrl(`/inventory/inventory-details/${this.ntfctnData.inventory_id}`);
        break;

      case 'lead':
        this.router.navigateByUrl(`/leads/lead-details/${this.ntfctnData.lead_id}/${this.ntfctnData.user_id}`);
        break;

      case 'dealer_liquidation_data':
        this.router.navigate(['database']);
        break;

      case 'callback_request':
        this.router.navigateByUrl(`/leads/lead-details/${this.ntfctnData.lead_id}/${this.ntfctnData.user_id}`);
        break;

      default:
        break;
    }
  }
}
