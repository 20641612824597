import { Component, OnInit } from '@angular/core';
import { DealerProfileService } from '../services/dealer-profile.service';
import { LanguageService } from '../services/language.service';
import { AdditionalService } from '../services/additional.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { languages } from '../../../../buyer/src/app/config';

@Component({
  selector: 'dealer-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  dealerProfile: any;
  secondaryData = [];
  languages = languages;

  profile = {
    name: '',
    location: '',
    data: []
  };
  editEnabled: boolean;

  showLoader = true;
  language;
  additionalData = new FormGroup({
    secondary_name: new FormControl([Validators.required]),
    secondary_phone_number: new FormControl([Validators.required]),
  });
  private subscription: Subscription = new Subscription();
  constructor(private _language: LanguageService,
    private dealerProfileService: DealerProfileService,
    private dataService: AdditionalService) {
    let lang = localStorage.getItem('language');
    if (lang != undefined) {
      this.language = lang;
    }
  }

  ngOnInit() {
    this.editEnabled = false;
    this.getInitialData();
  }
  onChange(language) {
    this._language.translateTo(language);
    localStorage.setItem('language', language);

  }

  getInitialData() {
    let subscription = this.dealerProfileService.getDealerProfile().subscribe(res => {

      this.showLoader = false;
      this.dealerProfile = res['data'];
      if (this.dealerProfile.inventory_district != null && this.dealerProfile.inventory_state != null) {
        this.dealerProfile.location = `${this.dealerProfile.inventory_district.name}, ${this.dealerProfile.inventory_state.name}`
      }
      else if (this.dealerProfile.inventory_district != null) {
        this.dealerProfile.location = this.dealerProfile.inventory_district.name;
      }
      else if (this.dealerProfile.inventory_state != null) {
        this.dealerProfile.location = this.dealerProfile.inventory_state.name;
      }
      else this.dealerProfile.location = 'NA';
      this.dealerProfile.location = `${this.dealerProfile.district}, ${this.dealerProfile.state}`;
      this.formatData();
    },
      error => {
      });

    this.subscription.add(subscription);

  }

  edit() {
    this.editEnabled = true;
  }


  formatData() {
    this.profile.name = this.dealerProfile.name;
    this.profile.location = this.dealerProfile.location;

    let obj1 = {
      name: 'phone-no',
      value: this.dealerProfile.phone_number
    };
    let obj2 = {
      name: 'location',
      value: this.dealerProfile.location
    };
    this.profile.data.push(obj1);
    this.profile.data.push(obj2);

    obj1 = {
      name: 'name',
      value: this.dealerProfile.secondary_name
    };
    obj2 = {
      name: 'phone-no',
      value: this.dealerProfile.secondary_phone_number
    };
    this.secondaryData.push(obj1);
    this.secondaryData.push(obj2);
    this.additionalData.setValue({
      secondary_name: this.secondaryData[0].value,
      secondary_phone_number: this.secondaryData[1].value
    });
    this.editEnabled = false;
  }



  updateAdditionalDetails() {
    let subscription = this.dataService.saveAdditionalData(this.additionalData.value).subscribe(res => {


      this.profile.data = [];
      this.secondaryData = [];
      this.getInitialData();
    },
      error => {
      });

    this.subscription.add(subscription);


  }
  myFun() {

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

    this.subscription.unsubscribe();

  }
}
