import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  unreadNtfctnChange = new BehaviorSubject<any>(true);
  unreadNtfctnCntChange = this.unreadNtfctnChange.asObservable();
  environmentUrl: string;
  constructor(private http: HttpClient) { this.environmentUrl = environment.api }

  getNotificationCount() {
    let user_id = localStorage.getItem("user_id");
    return this.http.get(`${this.environmentUrl}/user/${user_id}/get_fav_and_notif_count`);
  }
}
