import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  private endpoint = environment.api;
  constructor(public http: HttpClient) {
    this.endpoint = environment.api;
  }

  putContactUs(data) {
    data.dealer_id = localStorage.getItem('dealer_Id');
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return this.http.post(`${this.endpoint}/user/contact-us`, queryString, { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) });
  }
}
