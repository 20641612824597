import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RemoveInventory, AddInventoryId } from '../store/actions/inventory.action';
import { HttpBaseService } from './httpBase.service';
import { imageUpload } from '../models/dealer-upload.model';

@Injectable({
  providedIn: 'root'
})
export class DealerService extends HttpBaseService {
  dealer_id: any
  editInv: boolean = false;
  private environmentUrl = environment.api;

  constructor(public http: HttpClient,
    private router: Router,
    private store: Store<any>) {
    super();
    this.environmentUrl = environment.api;

    this.dealer_id = localStorage.getItem('dealer_Id');

  }

  uploadImage(inventoryId: number, uploadInfo: imageUpload) {

    return this.http.post(`${this.environmentUrl}/inventory/asset/${inventoryId}/upload/`, uploadInfo)
 
  }


  cancelUploadInv() {
    this.router.navigate(['/dashboard']);
    this.store.dispatch(new RemoveInventory());
  }



}
