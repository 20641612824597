import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'dealer-whatsapp-call-sms',
  templateUrl: './whatsapp-call-sms.component.html',
  styleUrls: ['./whatsapp-call-sms.component.scss']
})
export class WhatsappCallSmsComponent implements OnInit {

  @Input() number;
  @Input() callback;
  whatsappNum: string;
  sms: string;
  call: string;


  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
    // this.sms = `sms:${this.number}`;
    // this.whatsappNum = `+91${this.number}`;
    // this.call = `+91${this.number}`;

    // console.log('this.sms',this.sms);
    // console.log('this.whatsappNum',this.whatsappNum);
    // console.log('this.call',this.call);
  }
  sanitize() {
    return this.sanitizer.bypassSecurityTrustUrl(this.sms);
  }

  ngOnChanges() {
   this.sms = `sms:${this.number}`;
    this.whatsappNum = `+91${this.number}`;
    this.call = `+91${this.number}`;


  }

}
