import { Component, OnInit } from '@angular/core';
import { InventoryUploadService } from '../../inventory-upload/services/inventory-upload.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private inventoryUploadService: InventoryUploadService) { }

  ngOnInit() {

  }


  addInventory() {
    this.inventoryUploadService.isInventoryEdit.next(false);
  }
}
