import { OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { AppState } from './store/dealer-store.module';
import { AddInventory } from './store/actions/inventory.action';
import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { LanguageService } from './services/language.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
// import {LocalStorageKeys} from './shared/keys';
import {
  NavigationStart, NavigationEnd,
  NavigationCancel, NavigationError, Event
} from '@angular/router';
import { DashboardService } from './services/dashboard.service';
import { HeaderService } from './services/header.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'TAFE';
  subTitle = "Dealer Application";
  showLoadingIndicator = true;

  constructor(public router: Router,
    private _language: LanguageService,

    private store: Store<AppState>,
    private swUpdate: SwUpdate,
    private afMessaing: AngularFireMessaging,
    private _router: Router,
    private dashboardService: DashboardService,
    private headerService: HeaderService
  ) {
    let lang = localStorage.getItem('language');
    if (lang == undefined) {
      this._language.translateTo('en');
      localStorage.setItem('language', 'en');
      //   this.translate.setDefaultLang('en');
    }
    else {
      this._language.translateTo(lang);
    }


    //Route Loader
    // Subscribe to the router events observable
    this._router.events.subscribe((routerEvent: Event) => {

      // On NavigationStart, set showLoadingIndicator to ture
      if (routerEvent instanceof NavigationStart) {
        this.showLoadingIndicator = true;
      }

      // On NavigationEnd or NavigationError or NavigationCancel
      // set showLoadingIndicator to false
      if (routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationError ||
        routerEvent instanceof NavigationCancel) {
        this.showLoadingIndicator = false;
      }

    });
  }

  ngOnInit() {

    this.store.dispatch(new AddInventory([]));

    //this.authenticationService.getUserStatus();
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("New version available. Load New Version?")) {
          window.location.reload();
        }
      });
    }

    //firebase
    this.afMessaing.requestToken
      .subscribe(
        (token) => {
          localStorage.setItem('browser_token', token);
          const user = localStorage.getItem('user_id');
          // tslint:disable-next-line:triple-equals
          if (user != undefined || user != null) {
            this.dashboardService.sendBrowserToken()
              .subscribe(
                data1 => { },
                error1 => { }
              );
          }
        },
        (err) => { }
      );
    this.afMessaing.messages.subscribe((message) => {
      console.log(message);
      this.headerService.unreadNtfctnChange.next(true) });


  }

  /**
   * Check and register for updates
   */
  // private checkForUpdates() {
  //
  //   this.swUpdate.available.subscribe(event => {
  //     // you can ask the user here
  //     // if (promptUser(event)) {
  //     // //  swUpdate.activateUpdate().then(() => document.location.reload());
  //     // }
  //   });
  //   this.swUpdate.activated.subscribe(event => {
  //  });
  // }

  /**
   * stub to subscribe to push
   * no server for notifications in here..
   */
  // private registerToPushNotifications() {
  //   this.swPushListener
  //     .requestSubscription({ serverPublicKey: this.PUBLIC_KEY })
  //     .then(sub => {
  //       this.sub = sub;
  //     //  console.log('sub:', this.sub.toJSON());
  //       this.pushService.addSubscriber(this.sub).subscribe(result => {
  //       //  console.log('Add subscriber request answer', result);
  //       });
  //     });
  //   this.swPushListener.messages.subscribe(message => {
  //  //   console.log('Message received', message);
  //   });
  // }


  // ngAfterViewInit() {
  //   let deferredPrompt;
  //
  //   window.addEventListener('beforeinstallprompt', (e) => {
  //     // Prevent Chrome 67 and earlier from automatically showing the prompt
  //     e.preventDefault();
  //     // Stash the event so it can be triggered later.
  //     deferredPrompt = e;
  //   });
  //
  //   // this.elementRef.nativeElement.querySelector('btnAdd').style.display = 'block';
  //   this.elementRef.nativeElement.querySelector('btnAdd')
  //     .addEventListener('click', (e) => {
  //       // hide our user interface that shows our A2HS button
  //       // this.elementRef.nativeElement.querySelector('btnAdd').style.display = 'none';
  //       // Show the prompt
  //       deferredPrompt.prompt();
  //       // Wait for the user to respond to the prompt
  //       deferredPrompt.userChoice
  //         .then((choiceResult) => {
  //           if (choiceResult.outcome === 'accepted') {
  //             console.log('User accepted the A2HS prompt');
  //           } else {
  //             console.log('User dismissed the A2HS prompt');
  //           }
  //           deferredPrompt = null;
  //         });
  //     });
  //
  //   window.addEventListener('appinstalled', (evt) => {
  //     console.log('a2hs installed');
  //   });
  //
  // }


}
