import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../app/services/authentication.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(private router: Router, private authenticationService: AuthenticationService) { }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                //  console.log('error catched in httperror')
                if (error.status == 403) {
                    this.authenticationService.logout();
                    // this.router.navigate(['/landing']);
                } else if (error.status == 401) {
                    if (req.url.endsWith("user/refresh-token")) {
                        this.authenticationService.logout();
                    } else {
                        this.authenticationService.getRefreshToken(this.authenticationService.currentUser.refresh).subscribe(response => {
                            location.reload();
                        })
                    }
                }
                else {
                    this.router.navigate([`/error/${error.status}`]);
                    if (error.status === 0) {
                        return throwError('Please check your internet connection');
                    }
                    if (error.status === 404) {
                        return throwError('Page not found');
                    }
                    console.error(
                        `Backend returned code ${error.status}, ` +
                        `body was: ${error.error}`);
                }
                return throwError(error.error);

            })
        );


    }
}
