import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { DealerService } from './dealer.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private endpoint = environment.api;

  constructor(public http: HttpClient, public dealerService: DealerService) {
    this.endpoint = environment.api;
  }

  dashboardData(param) {
    let dealer_id = this.dealerService.dealer_id;
    return this.http.get(`${this.endpoint}/inventory/${dealer_id}/dealer-dashboard/`, { params: param });
  }

  sendBrowserToken() {
    //send browser Token
    let user_id = localStorage.getItem('user_id');
    let registration_id = localStorage.getItem('browser_token');
    let data = {
      user_id: user_id,
      registration_id: registration_id
    }
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(`${this.endpoint}/user/register-browser`, queryString, { headers: headers })
  }
}
