import { Action } from "@ngrx/store";

export enum InventoryActionTypes {
  ADD_INVENTORY = '[INVENTORY] Add Inventory',
  REMOVE_INVENTORY = '[INVENTORY] Remove Inventory',
  UPDATE_INVENTORY = '[INVENTORY] Update Inventory',

  ADD_INVVENTORY_ID = '[INVENTORY] Add Inventory Id',

  REMOVE_INVENTORY_ID = '[INVENTORY], Remove Inventory Id'
}

export class AddInventory implements Action {
  readonly type = InventoryActionTypes.ADD_INVENTORY;
  constructor(public payload: any) { }
}

export class RemoveInventory implements Action {
  readonly type = InventoryActionTypes.REMOVE_INVENTORY;
  constructor() { }
}

export class UpdateInventory implements Action {
  readonly type = InventoryActionTypes.UPDATE_INVENTORY;
  constructor(public payload: { index: number, data: any }) { }
}

export class AddInventoryId implements Action {
  readonly type = InventoryActionTypes.ADD_INVVENTORY_ID;
  constructor(public payload: any) { }
}

export class RemoveInventoryId implements Action {

  readonly type = InventoryActionTypes.REMOVE_INVENTORY_ID;
  constructor() { }
}

export type Action = AddInventory | RemoveInventory | UpdateInventory | AddInventoryId | RemoveInventoryId;
