import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { newsFeedResponse } from '../models/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'dealer-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {
  showLoader = true;
  prevUrl: string;
  nextUrl: string;
  scroll = false;

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 5;
  notificationList = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private _location: Location) {
  }

  ngOnInit() {
    this.getNotifications();
  }

  getNotifications() {
    this.notificationService.getNotifications().subscribe((res: newsFeedResponse) => {
      this.showLoader = false;
      this.nextUrl = res.data.links.next;
      this.prevUrl = res.data.links.previous;
      this.notificationList = res.data.results;
    }, error => { });
  }


  scrollDown(e) {
    if (this.nextUrl != null) {
      this.notificationService.getPrevNext(this.nextUrl).subscribe((res: newsFeedResponse) => {
        this.showLoader = false;
        this.notificationList = [...this.notificationList, ...res.data.results]
        this.prevUrl = res.data.links.previous;
        this.nextUrl = res.data.links.next;
      },
        error => { });
    }

  }

  scrollUp(e) { }

  public backUrl() {
    this._location.back();
  }

  markAllRead() {
    this.notificationService.markAllRead().subscribe(res => {
      this.getNotifications();
    })
  }

}
