import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DealerHelpdeskService {
  private endpoint = environment.api;
  constructor(private http: HttpClient) {
    this.endpoint = environment.api;
  }

  getDelaerHelpdesk() {
    let dealer_id = localStorage.getItem('dealer_Id');
    let data = {
      dealer_id: dealer_id
    }
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(`${this.endpoint}/user/helpdesk`, queryString, { headers: headers });
  }
}
