import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-label-with-buttons',
  templateUrl: './label-with-buttons.component.html',
  styleUrls: ['./label-with-buttons.component.scss']
})
export class LabelWithButtonsComponent implements OnInit {

  @Input() title: any;
  @Input() buttonTitle: any;
  @Input() id: any;
  @Output() select = new EventEmitter<any>();

  constructor() { }

  styles = ['model-box', 'styleOnClick'];

  ngOnInit() {
  }

  ngOnChanges() {
    this.buttonTitle.forEach(element => {
      element.id = `${this.id}_${element.value}`;
    });
  }

  enableCheck(event) {
    this.select.emit(event);
  }

}
