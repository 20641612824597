import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {

    constructor(private _translate: TranslateService){}

    translateTo(language){
        this._translate.use(language);
    }


}

