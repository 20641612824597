import { Action, LeadsListActionTypes } from '../actions/leads-list.action';

export interface LeadsListState {
  leadsSortFilter: any;
}

const initialState: LeadsListState = {
  leadsSortFilter: null
};

export function leadsListReducer(state= initialState, action: Action) {
  switch (action.type) {
    case LeadsListActionTypes.ADD_SORT_FILTER:
      return { ...state, leadsSortFilter: action.payload };

    case LeadsListActionTypes.REMOVE_SORT_FILTER:
      return { ...state, leadsSortFilter: null };

    default: return state;
  }
}
