import { Injectable } from '@angular/core';
import { environment } from 'dealer/src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/dealer-store.module';
import { DealerService } from '../../services/dealer.service';
import { format } from 'util';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InventoryUploadService {
  storeInvId: any;
  private endpoint = environment.api;
  hp: string;
  isInventoryEdit = new BehaviorSubject(false);
  storeData;
  constructor(public http: HttpClient, private store: Store<AppState>, public dealerService: DealerService) {
    this.endpoint = environment.api;
    this.store.select(data => {
      this.storeData = data.inventoryData.inventoryData;
      this.storeInvId = data.inventoryData.inventory_id;
    }).subscribe();
  }

  getInvMakeModel(feature = 'brand', brand_id?) {
    return this.http.get(`${this.endpoint}/inventory/get-tractor-details/`,
      { params: { feature: feature, brand_id: brand_id } });
  }

  createInventory(status) {
    if (this.storeData[0] != null) {
      //  let data = this.storeData[0];
      this.storeData[0]['status'] = status;
      this.storeData[0]['dealer'] = this.dealerService.dealer_id;
      if (localStorage.getItem("edsp_id") !== "null") {
        this.storeData[0]['edsp'] = JSON.parse(localStorage.getItem("edsp_id"));
      }
      return this.http.post(`${this.endpoint}/inventory/create/`, this.storeData[0]);
    }
  }

  updateInventory() {
    if (this.storeData[0] != null) {
      this.storeData[0].status = 'draft';
      let formData = new FormData();
      let data = this.storeData[0];
      for (let i in data) {
        formData.append(i, data[i]);
      }

      formData.append('dealer', this.dealerService.dealer_id);
      delete this.storeData[0].long_lat;
      return this.http.put(`${this.endpoint}/inventory/${this.storeInvId}/update/`, this.storeData[0]);
    }
  }
  changeStatus(invId, arr) {

    return this.http.put(`${this.endpoint}/inventory/${invId}/update/`, { "tractor_model": arr[0], "status": arr[1] });
  }

  deleteAsset(invId, assetId) {
    return this.http.delete(`${this.endpoint}/inventory/asset/${invId}/remove/${assetId}`)
  }


  removeInv(invId) {
    return this.http.delete(`${this.endpoint}/inventory/${invId}/delete`);

  }

  getPresignedUrl(invId, file_name) {

    let formdata = new FormData();
    formdata.append("file_name", file_name);
    return this.http.post<any>(`${this.endpoint}/inventory/asset/${invId}/get-presigned-url/`, formdata);
  }

  uploadFiletoS3(url, payload, file) {

    let headers = new HttpHeaders();
    headers.append("Content-Type", "video/mp4");
    headers.append("Access-Control-Allow-Origin", "*");

    let formdata = new FormData();
    for (let i in payload.fields) {
      formdata.append(i, payload.fields[i])
    }

    formdata.append("file", file);

    return this.http.post(`${url}`, formdata);

  }


  saveUploadedToDatabase(invId, payload) {
    let formdata = new FormData();
    for (let i in payload) {
      formdata.append(i, payload[i]);
    }

    return this.http.post<any>(`${this.endpoint}/inventory/asset/${invId}/save-asset-info/`, formdata);
  }





}
