import { Component, OnInit } from '@angular/core';
import { faqConfig } from './faq-config';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dealer-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faq = faqConfig;

  constructor(private translate: TranslateService) {
    translate.setDefaultLang('en');
  }

  ngOnInit() {
  }

}
