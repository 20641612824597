import { Component, OnInit } from '@angular/core';
import { faqConfig } from '../faq/faq-config';
import { tncConfig, tncDisclaimerLegalNote } from './tnc-config';
//import * as data from '../../assets/i18n/en.json';

@Component({
  selector: 'dealer-terms-n-condition',
  templateUrl: './terms-n-condition.component.html',
  styleUrls: ['./terms-n-condition.component.scss']
})
export class TermsNConditionComponent implements OnInit {

  tnc = tncConfig;
  disclaimer = tncDisclaimerLegalNote;
  // tncData = data.tnc;
  constructor() {

    // console.log(this.tncData)
  }

  ngOnInit() {

  }

}
