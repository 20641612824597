import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  dealer_id: any
  editInv: boolean = false;
  private environmentUrl;
  unreadNtfctnChange = new BehaviorSubject<any>(null);
  unreadNtfctnCntChange = this.unreadNtfctnChange.asObservable();


  constructor(private http: HttpClient) {
    this.environmentUrl = environment.api;
  }

  getNotifications() {
    let user_id = localStorage.getItem('user_id')
    return this.http.get(`${this.environmentUrl}/newsfeed/${user_id}/newsfeed`);
  }

  getPrevNext(url) {
   
    return this.http.get(`${url}`);
  }
  markNotificationRead(id) {
    let user_id = localStorage.getItem('user_id');
    let body = {
      notif: id
    }
    return this.http.put(`${this.environmentUrl}/newsfeed/${user_id}/newsfeed`, {}, { params: body });
  }

  markAllRead() {
    let user_id = localStorage.getItem('user_id');
    let body = {
      notif: 'all'
    }
    return this.http.put(`${this.environmentUrl}/newsfeed/${user_id}/newsfeed`, {}, { params: body });
  }

}
