import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { DealerService } from './dealer.service';

@Injectable({
  providedIn: 'root'
})
export class DealerProfileService {
  private endpoint = environment.api;

  constructor(private http: HttpClient, private dealerService:DealerService) {
    this.endpoint = environment.api;
  }

  getDealerProfile() {
    let dealer_id = this.dealerService.dealer_id;
    if(localStorage.getItem("edsp_id")!="null"){
      dealer_id= localStorage.getItem("edsp_id");
    }
    return this.http.get(`${this.endpoint}/dealer/${dealer_id}/detail`);
  }
}
