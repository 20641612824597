
import { Action, StateDistrictActionTypes } from '../actions/state-district.action';

export interface StateDistrictState {
  stateDistrict: any;

}

const initialState: StateDistrictState = {
  stateDistrict: null
};

export function stateDistrictReducer( state = initialState, action:Action)
{
  switch (action.type) {
    case StateDistrictActionTypes.ADD_STATE_DISTRICT:
      return { ...state, stateDistrict: action.payload };

    default: return state;
  }
}
