import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'financeFilter'
})
export class FinanceLeadsSearchFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      if (!(it.model === null)) {
        return (it.model.toLowerCase().includes(searchText)) || (it.first_name.toLowerCase().includes(searchText));
      }
    });
  }
}
