import { Component, OnInit } from '@angular/core';
import { DealerHelpdeskService } from '../services/dealer-helpdesk.service'
import { Subscription } from 'rxjs';

@Component({
  selector: 'dealer-helpline',
  templateUrl: './helpline.component.html',
  styleUrls: ['./helpline.component.scss']
})
export class HelplineComponent implements OnInit {

  name: any;
  post: any;
  contactNumber: number;

  private subscription: Subscription = new Subscription();

  constructor(private dealerHelpdesk: DealerHelpdeskService) {
    this.name = "Helpdesk";
    this.post = "Support";
  }

  ngOnInit() {
    let sub = this.dealerHelpdesk.getDelaerHelpdesk().subscribe(res => {
      this.contactNumber = res['message'];
    }, error => {
    });
    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
