import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { DealerService } from '../../services/dealer.service';


@Injectable({
  providedIn: 'root'
})
export class ListingService {

  private endpoint = environment.api;


  constructor(public http: HttpClient, public dealerService: DealerService) {
    this.endpoint = environment.api;
  }

  getInventoryList(data?) {
    let dealer_id = this.dealerService.dealer_id;
    return this.http.post(`${this.endpoint}/dealer/${dealer_id}/get-dealer-inventories/`, data);
  }

  getArchivedList(data?) {
    
    let dealer_id = this.dealerService.dealer_id;
    return this.http.post(`${this.endpoint}/inventory/${dealer_id}/get-archive-data/`, data);
  }

  getMakeList(id: number, data?) {
    return this.http.get(`${this.endpoint}/inventory/make-live/${id}/`, data);
  }



}
