import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-name-box',
  templateUrl: './name-box.component.html',
  styleUrls: ['./name-box.component.scss']
})
export class NameBoxComponent implements OnInit {

  constructor() {
  }
  @Input() style: any;
  @Input() name: any;
  @Input() id: any;
  @Input() extDetails: any;
  @Output() select = new EventEmitter<any>();


  aa = (a: any) => {
    // console.log(a);
  }

  ngOnInit() {

  }

  ngOnChanges() {

  }


  selectMenu(event: object) {
    this.select.emit(event);
  }

}
