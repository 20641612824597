import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LabelWithButtonsComponent } from './label-with-buttons/label-with-buttons.component';

import { MenuBoxComponent } from './menu-box/menu-box.component';
import { NameBoxComponent } from './name-box/name-box.component';
import { NewsfeedComponent } from './newsfeed/newsfeed.component';
import { SummaryContainerComponent } from './summary-container/summary-container.component';

import { UserDataComponent } from './user-data/user-data.component';
import { MatFormFieldModule, MatSidenavModule, MatSnackBarModule, MatBadgeModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatProgressSpinnerModule } from '@angular/material';

// import { SummaryComponent } from './summary/summary.component';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TractorInfoComponent } from './tractor-info/tractor-info.component';
import { UploadSummaryComponent } from './upload-summary/upload-summary.component';
import { SlideshowModule } from 'ng-simple-slideshow';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { MatSelectModule } from '@angular/material/';
import { WhatsappCallSmsComponent } from './whatsapp-call-sms/whatsapp-call-sms.component';
import { ErrorMsgComponent } from './error-msg/error-msg.component';
import { LoaderComponent } from './loader/loader.component';
import { DataTractorCardComponent } from '../database/data-tractor-card/data-tractor-card.component';
import { NotificationCardComponent } from './notification-card/notification-card.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NumberDecimalPipe } from './pipes/number-decimal.pipe';

import { LeadsSearchFilterPipe } from './filters/leads-search-filter';
import { FinanceLeadsSearchFilterPipe } from './filters/finance-lead-search.filter';

@NgModule({
  declarations: [HeaderComponent,
    FooterComponent,
    LabelWithButtonsComponent,
    FinanceLeadsSearchFilterPipe,
LeadsSearchFilterPipe,
    MenuBoxComponent,
    NameBoxComponent,
    NewsfeedComponent,
    UploadSummaryComponent,
    SummaryContainerComponent,

    UserDataComponent,
    TractorInfoComponent,

    SnackbarComponent,
    WhatsappCallSmsComponent,
    ErrorMsgComponent,
    LoaderComponent,
    DataTractorCardComponent,
    NotificationCardComponent,
    NumberDecimalPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    SlideshowModule,
    MatSnackBarModule,
    NgbModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatBadgeModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LabelWithButtonsComponent,

    MenuBoxComponent,
    NameBoxComponent,
    NewsfeedComponent,
    UploadSummaryComponent,
    SummaryContainerComponent,

    UserDataComponent,
    TranslateModule,
    TractorInfoComponent,

    SnackbarComponent,
    WhatsappCallSmsComponent,
    ErrorMsgComponent,
    LoaderComponent,
    MatProgressSpinnerModule,
    DataTractorCardComponent,
    NotificationCardComponent,
    InfiniteScrollModule,
    NumberDecimalPipe,
    LeadsSearchFilterPipe,
    FinanceLeadsSearchFilterPipe
  ]
})
export class SharedModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
