import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  //@Input() notification: any; // Tr
  public dashboardData;
  summaryOptions = ['this-week', 'last-week', 'this-month', 'last-month', 'this-year'];
  thisWeekFromTo: { from_date: string; to_date: string; };
  error = false;
  errorMsg: any;
  showLoader: boolean = true;
  ntfctnData: any;
  private subscription: Subscription = new Subscription();
  notificationData: any;
  status: string;
  model: any;
  isClosed = false;
  filteredData: any;

  constructor(public http: HttpClient,
    public dashboardService: DashboardService,
    private translate: TranslateService,
    private notificatioService: NotificationService,
    private router: Router,
    // public Pwa: PwaServiceService
  ) {
    this.translate.setDefaultLang('en');
  }

  initialSettings() {
    let date = new Date();
    const today = this.getDdMmYYYYFormat(date);
    const day = date.getDay();
    const thismondd = date.getDate() - day + (day == 0 ? -6 : 1);
    let thisWeekMonDate = new Date(date.setDate(thismondd));

    //this week from-to dates
    let fromToDate = {
      from_date: this.getDdMmYYYYFormat(thisWeekMonDate),
      to_date: today
    };

    this.thisWeekFromTo = fromToDate;
    return fromToDate;
  }


  ngOnInit() {
    let date = new Date();
    const todayNotification = this.getYYYYMMddFormat(date);
    this.notificatioService.getNotifications().subscribe(res => {
      this.notificationData = res;
      this.filteredData = this.notificationData.data.results.filter(notification => {
        return (
          notification.notification_data.status === 'archive' &&
          notification.notification_data.date === todayNotification &&
          !notification.is_read
        );
      });
    });
    const fromToDate = this.initialSettings();
    let sub1 = this.dashboardService.dashboardData(fromToDate).subscribe(res => {
      let browserToken = localStorage.getItem('browser_token');
      if (browserToken != undefined || browserToken != null) {
        this.dashboardService.sendBrowserToken()
          .subscribe(
            data1 => { },
            error1 => { }
          );
      }
      this.dashboardData = res['data'];
      this.showLoader = false;
      this.dashboardData.periodic.title = "this-week-summary";
    }, error => {
      this.error = true;
      this.errorMsg = error;
    });
    this.subscription.add(sub1);
  }


  getFilterDates(filterChoosen) {
    let date = new Date();
    let todayMM = ('0' + (date.getMonth() + 1)).slice(-2);
    let todayYYYY = date.getFullYear();

    let today = this.getDdMmYYYYFormat(date);

    let day = date.getDay();
    let thismondd = date.getDate() - day + (day == 0 ? -6 : 1);
    let thisWeekMonDate = new Date(date.setDate(thismondd));
    let fromToDate;

    if (filterChoosen == 'Last Week') {
      let lastweekMonDate = new Date(thisWeekMonDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      let lastweekSunDate = new Date(thisWeekMonDate.getTime() - 1 * 24 * 60 * 60 * 1000);

      fromToDate = {
        from_date: this.getDdMmYYYYFormat(lastweekMonDate),
        to_date: this.getDdMmYYYYFormat(lastweekSunDate)
      };
      return fromToDate;
    }

    else if (filterChoosen == 'this-month') {
      fromToDate = {
        from_date: `01-${todayMM}-${todayYYYY}`,
        to_date: today
      };
      return fromToDate;
    }

    else if (filterChoosen == 'last-month') {
      let lastMonthFirstDate = new Date(date.getFullYear(), (date.getMonth() - 1), 1);
      let lastMonthLastDate = new Date(date.getFullYear(), (date.getMonth()), 0);

      fromToDate = {
        from_date: this.getDdMmYYYYFormat(lastMonthFirstDate),
        to_date: this.getDdMmYYYYFormat(lastMonthLastDate)
      };
      return fromToDate;
    }

    else if (filterChoosen == 'this-year') {
      fromToDate = {
        from_date: `01-01-${todayYYYY}`,
        to_date: today
      };
      return fromToDate;
    }
    else { return this.thisWeekFromTo; }
  }


  getDdMmYYYYFormat(date) {
    let dd = ('0' + (date.getDate())).slice(-2);
    let mm = ('0' + (date.getMonth() + 1)).slice(-2);
    let yyyy = date.getFullYear();
    return `${dd}-${mm}-${yyyy}`;
  }

  getYYYYMMddFormat(date) {
    let dd = ('0' + (date.getDate())).slice(-2);
    let mm = ('0' + (date.getMonth() + 1)).slice(-2);
    let yyyy = date.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }


  selectOption(event) {
    let dates = this.getFilterDates(event);
    let sub = this.dashboardService.dashboardData(dates).subscribe(res => {
      this.dashboardData.periodic = res['data'].periodic;
      this.dashboardData.periodic.title = `${event}-summary`;
    }, error => { });

    this.subscription.add(sub);
  }

  myFun(event) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  closeNotification(id: number) {
    this.isClosed = true;
    this.markNotificationRead(id);
  }

  markNotificationRead(id: number) {
    this.notificatioService.markNotificationRead(id).subscribe(res => {
    });
  }

  redirectNotification(id: number, inventory_id: number) {
    this.markNotificationRead(id);
    this.router.navigateByUrl(`/inventory/inventory-details/${inventory_id}`);
  }

}
