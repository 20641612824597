import { Action } from "@ngrx/store";

export enum StateDistrictActionTypes {
   ADD_STATE_DISTRICT = '[STATE_DISTRICT] Add State District'
}

export class AddStateDistrict implements Action {
    readonly type = StateDistrictActionTypes.ADD_STATE_DISTRICT;
    constructor(public payload: any) { }
}


export type Action = AddStateDistrict;
