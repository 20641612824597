import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberDecimal'
})
export class NumberDecimalPipe implements PipeTransform {

  transform(value: any): any {
    let decimalVal = ((value) / 100000).toFixed(2);

    return decimalVal;
  }

}
