import { Component, OnInit } from '@angular/core';
import { ListingService } from '../inventory/services/listing.service';
import { Router } from '@angular/router'
import { DealerProfileService } from '../services/dealer-profile.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'dealer-buyer-view',
  templateUrl: './buyer-view.component.html',
  styleUrls: ['./buyer-view.component.scss']
})
export class BuyerViewComponent implements OnInit {
  inventory: any;
  imgUrl: any;
  inventoryList: any = [];
  showLoader = true;
  secondaryContact: any;
  private subscription: Subscription = new Subscription();

  constructor(private inventoryService: ListingService,
    private route: Router,
    private dealerProfileService: DealerProfileService
  ) { }

  ngOnInit() {
    let subscription = this.dealerProfileService.getDealerProfile().subscribe(res => {
      const temp = res['data'];
      this.secondaryContact = temp.secondary_phone_number;
    });

    this.getInvList();
    this.subscription.add(subscription)
  }

  getInvList() {
    let sub = this.inventoryService.getInventoryList().subscribe(res => {
      this.inventoryList = [];
    
      const temp = res['data'];

      if (temp.length === 0) {
        this.inventoryList = temp;
      }

      if (temp.approved) {
        this.inventoryList = temp.approved;
      }
      this.showLoader = false;

    })

    this.subscription.add(sub);
  }

  goBack() {
    this.route.navigate(['/my-profile'])
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
  }

}
