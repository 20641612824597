import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-msg',
  templateUrl: './error-msg.component.html',
  styleUrls: ['./error-msg.component.scss']
})
export class ErrorMsgComponent {
  errorMsg = 'NO INTERNET CONNECTION';

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(res => {
      //  console.log(res);
      if (res.errorId !== '0') {
        this.errorMsg = 'something went wrong';
      }
    });
  }
  reload() {
    this.router.navigate(['/dashboard']);
  }
}
