import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdditionalService } from '../services/additional.service';
import { Router } from '@angular/router';
import { tncConfig } from './tnc-config';

import { AuthenticationService } from '../services/authentication.service';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
// import {AuthenticationService} from '../services/authentication.service'

@Component({
  selector: 'app-additional-details',
  templateUrl: './additional-details.component.html',
  styleUrls: ['./additional-details.component.scss']
})
export class AdditionalDetailsComponent implements OnInit {
  tnc = tncConfig;
  marked = false;
  additionalData = new FormGroup({
    secondary_name: new FormControl('', [Validators.required]),
    secondary_phone_number: new FormControl('', [Validators.required]),
  });
  private subscription: Subscription = new Subscription();

  constructor(private dataService: AdditionalService,
    private auth: AuthenticationService,
    public router: Router, private translate: TranslateService) {
    translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.marked = false;
  }

  dataSave() {

    this.additionalData.value["policy_accepted"] = "True";

    let subscription = this.dataService.saveAdditionalData(this.additionalData.value).subscribe(res => {
      this.additionalData.reset();
      this.auth.removeAdditionalDetails();
      this.router.navigate(['dashboard']);
    },
      error => {
      });
    this.subscription.add(subscription);
  }
  
  cancel() {
    this.additionalData.value["policy_accepted"] = "True";
    let subscription = this.dataService.saveAdditionalData(this.additionalData.value).subscribe(res => {
      this.additionalData.reset();
      this.auth.removeAdditionalDetails();
      this.router.navigate(['dashboard']);
    },
      error => {
      });
    this.subscription.add(subscription);
  }


  opentc() {
    document.getElementById("exampleModalCenter").style.display = "block";

  }
  closetc() {
    document.getElementById("exampleModalCenter").style.display = "none";
  }

  toggleVisibility(event) {
    this.marked = event.target.checked;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
  }
}
