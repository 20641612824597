import { Action, DatabaseListActionTypes } from '../actions/database-list.action';

export interface DatabaseListState {
 databaseSortFilter: any;
}

const initialState: DatabaseListState = {
  databaseSortFilter: null
};

export function databaseListReducer(state=initialState , action: Action)
{
  switch (action.type) {
    case DatabaseListActionTypes.ADD_SORT_FILTER:
      return { ...state, databaseSortFilter: action.payload };

    case DatabaseListActionTypes.REMOVE_SORT_FILTER:
      return { ...state, databaseSortFilter: null };

    default: return state;
  }
}
