import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { newsFeedResponse } from '../../models/response.model';

@Component({
  selector: 'app-newsfeed',
  templateUrl: './newsfeed.component.html',
  styleUrls: ['./newsfeed.component.scss']
})
export class NewsfeedComponent implements OnInit {

  newsfeedList: any;
  constructor(private notificationService: NotificationService) { }

  ngOnInit() {
    this.getNewsfeed();
  }


  getNewsfeed() {
    this.notificationService.getNotifications().subscribe((res: newsFeedResponse) => {
      this.newsfeedList = res.data.results;
    });
  }

}
