import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HttpBaseService } from './httpBase.service';
import { DealerService } from './dealer.service';
import { FormatWidth } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AdditionalService extends HttpBaseService {

  private endpoint = environment.api;

  constructor(public http: HttpClient, private dealerService: DealerService) {
    super();
    this.endpoint = environment.api;
  }

  saveAdditionalData(updateData) {
    let dealerId = this.dealerService.dealer_id;
    if(localStorage.getItem("edsp_id")!="null"){
      dealerId= localStorage.getItem("edsp_id");
    }
    let data = new FormData();
    for (let i in updateData) {
      data.append(i, updateData[i]);
    }
    return this.http.put(`${this.endpoint}/dealer/${dealerId}/detail`, data);
  }
}
