export const faqConfig = [
  {
    "title": "About TractorBazar",
    "content": [
      {
        "que": "What is TractorBazar?",
        "ans": "TractorBazar is an online marketplace of exchange tractors where potential buyers meet sellers. You will be able to upload your used tractor inventory onto the platform along with all product information and tractor images/ videos. Buyers (aggregators/ farmer customers) can browse, filter and sort through the tractor listings and decide to contact you for any of your tractor(s) they wish to purchase. The final dealings and negotiation between you and the buyer will happen offline and the purchase transaction will be concluded physically."
      },

      {
        "que": "Is TractorBazar free?",
        "ans": "Yes! TractorBazar is free for everyone (dealers, aggregators and individual customers)."
      },
      {
        que: 'What are benefits that I get for selling or buying a used tractor from TractorBazar?',
        type: 'Subcategory',
        sellers: [
          'Access to a large network of interested buyers (aggregators and farmer customers)',
          'Potential for faster inventory turnover due to faster lead follow-up and improved conversion time',
          'Easier customer management as data on past customers gets captured and stored',
          'Digital repository of inventory- to help track and analyse inventory easily',
          'Increased convenience with capabilities to share images, follow-up on leads, send messages through WhatsApp'
        ]
        // sellers: [
        //   'Ability to sell to a wider range of audience – All India aggregators and individual(s)',
        //   'Enquiry classification (Saathi tier) & categorization (stages) for prioritization',
        //   'Convenient inventory and lead management, with ability to share images, follow-up, send marketing messages etc.',
        //   'Improved customer management: Dealers can record and store data on past customers in one convenient place, and broadcast message to all past customers with few simple clicks',
        //   'Direct in-app tractor image upload from camera/gallery with auto tools for image correction'
        // ]
      },
      {
        "que": "What is the difference between TractorBazar mobile app and TractorBazar website?",
        "ans": "The mobile app and the website are the same in terms of content. The only difference is the app is a “Progressive Web Application” which means that it can be added as a shortcut to the phone’s home screen without the need of installing it from the app store and can be accessed easily in a single-tap. The website is a web portal that runs directly in any browser on your desktop, laptop, smartphones and tablets."
      },
      {
        "que": "Can I also upload other used farm implements?",
        "ans": "No, currently the app is only used for uploading used tractors"
      },
      {
        "que": "Will TractorBazar take care of delivery of the tractor to the buyer?",
        "ans": "TractorBazar is just an online search discovery platform- you can upload used tractor inventory which will be viewed by the buyer. However, the negotiations and actual purchase transaction will happen offline between you and the buyer. Hence, you may choose to personally deliver the tractors or ask the buyer to collect it from the dealership."
      },







    ]
  },




  {
    "title": "App features- Product Upload",
    "content": [
      {
        "que": "How do I upload a tractor from my inventory on to the platform?",
        "ans": "You can upload a tractor by clicking on the “Add tractor” pop up on the bottom right corner of the landing page. Follow the sequence and enter all relevant details about your tractor along with pictures and videos. At the end, you will be able to view the summary of your product on 1 page- you can double check details/ make any changes my clicking on “edit”, once done send the product for approval by admin by clicking on “send for approval”"
      },
      {
        "que": "What happens when I click on “send for approval”?",
        "ans": "Clicking on “send for approval” sends the tractor for approval by the admin. Once admin approves the uploaded tractor- you will get a notification informing you about the same"
      },
      {
        "que": "Can I save a tractor as a draft and upload it later?",
        "ans": "Yes, clicking the “Save as draft” button will allow to save the information you have filled as a draft. You can later access your drafts from the inventory page and edit them before sending it for approval. The drafts can also be deleted from the inventory page."
      },
      {
        que: 'What does Offer: Standard/ special mean during product upload?',
        ans: 'If you wants to give a discount or any other scheme on the tractor, you should select special offer. The details of the offer can be included in the free text box which appears after clicking on special. If you do not want to give any discount/ scheme/ offer, select standard.'
      },
      {
        "que": "How does the price range get calculated when I am uploading a product?",
        "ans": "The lower value in the range will be Rs.20,000 less than the higher value. You have to input the higher value while uploading a tractor. Use your best judgement in entering the estimated price range. Ensure that the price range is an accurate reflection of the liquidation value for the tractor."
      },
      {
        "que": "Do I get guarantee on the price?",
        "ans": "Prices are negotiable. The price you enter should be the price you expect from liquidation. Further negotiations can be done through WhatsApp, SMS or calls with the buyer. No finalization of prices happens on this site"
      },

    ]
  },


  {
    "title": "App features- Landing page/ dealer dashboard",
    "content": [
      {
        "que": "What does “live tractors” mean in the overview section?",
        "ans": "It is the number of tractors from the dealer’s inventory live on the platform. A tractor uploaded by you becomes “live” when it get approved by the admin. Thus, tractors pending for admin approval and draft tractors are not a part of this list. Also once you sell/ liquidate a tractor, it is not included in “live” tractors any more."
      },
      {
        "que": "What does “no leads” mean in the overview section?",
        "ans": "It is the number of tractors which have not got any aggregator/ end customer leads (Leads means the potential buyers who have shown interest in your tractor). These are the tractors for which no customers have shown interest "
      },
      {
        "que": "What does “pending for approval” mean in the overview section?",
        "ans": "A tractor uploaded by you becomes live when it get approved by the admin. Pending for approval is the total number of tractors you have uploaded, but are not yet been approved by the admin. "
      },
      {
        "que": "How can I view notifications?",
        "ans": "You can view notifications by clicking the bell icon on the top right corner of the app screen. These include notifications when you have a lead for any of your tractors (i.e. a customer or aggregator has expressed interest), when there is an event taking place in your vicinity and when admin approves/ rejects your product upload"
      },
      {
        "que": "What does the newsfeed show?",
        "ans": "Newsfeed includes notifications on any leads you would have received on your tractors (sorted in order of recency)"
      }

    ]
  },


  {
    "title": "App features- Inventory/ Leads/ Database page",
    "content": [
      {
        "que": "How do I know how many people have seen the tractor?",
        "ans": "The number of leads and views for each live tractor can be seen in the summary of the listing on the inventory page. Leads is the number of potential buyers who have expressed interest in your tractor and wanted to contact you by clicking on “contact seller” from the buyer app. Views is the number of potential buyers who have just clicked on your tractor to view its details on the buyer app."
      },
      {
        "que": "Can I save tractor images I uploaded online on my phone?",
        "ans": "You can download all images by clicking on the down arrow button on the inventory page."
      },
      {
        "que": "How can I share the images of Tractor(s) from TractorBazar?",
        "ans": "You can share images of a tractor by clicking on the WhatsApp icon located next to the listing on the inventory page."
      },
      {
        "que": "Can I share multiple tractors/ my entire product catalogue with any buyer?",
        "ans": "You can use the filtering options at the top right corner of the inventory listing page to choose the tractors you want to share with a buyer. Once the filter is applied, you can share this filtered list with the buyer by clicking the “Share” icon right under the filter button"
      },
      {
        "que": "What does Platinum/Gold/Silver Saathi mean on leads page?",
        "ans": "Saathi Program is a loyalty program for aggregators. Aggregators receive points for every tractor purchase, and are categorised as Platinum/Gold/Silver basis their volume and usage of TractorBazar platform. The colour of the coin like symbol next to the name of the lead represents whether he is a Platinum/Gold/Silver saathi"
      },
      {
        "que": "What does the coloured circle on next to lead indicate?",
        "ans": "The coloured circle indicates the “stage” of the lead (explained in detail below). Different colours indicate different stages- Red (Hot), Yellow (Warm), Blue (Cold), Grey (dropped) and White (not contacted). The meaning of each of these stages has also been explained in the next answer."
      },
      {
        "que": "What does stage mean on the leads page?",
        "ans": "Stage is a way you can classify your leads based on your conversations with the interested buyer. You can classify leads into Hot/ Warm/ Cold/ Dropped from the leads management page. Hot stage means that the buyer who has very high potential for actually buying your tractor. Warm stage means the buyer is positively interested in the tractor and you can convince him to buy the tractor by putting in some additional effort. Cold stage means that the buyer does not seem to be very interested in buying the tractor. Dropped stage means a buyer who is not at all likely to buy the tractor and you decide not to pursue him at all."
      },
      {
        "que": "Where do I find information about buyers?",
        "ans": "The database pages shows the list of buyers who have purchased a used tractor from you. It gives information about each customer as well as the purchases made by him in the past. You also have the option to add any buyers who have purchases from you in the past to the database and maintain your personal logbook/ database of buyers"
      }
    ]
  },









  {
    "title": "E-DSP/ DEC specific questions",
    "content": [
      {
        "que": "I am a new (Exchange) E-DSP / Dealer Exchange Champion (DEC). What should I do to get the login credentials?",
        "ans": "Go to employee master screen in DMS, add your name mobile no. and E-DSP/DEC code. In the field “employee type” choose “mobile”. The no. will be registered in the app database and login will be created. One can use the login from the next working day."
      }

    ]
  },


  {
    "title": "Support & technical questions",
    "content": [
      {
        "que": "I am facing technical difficulty while trying to use the app. What should I do?",
        "ans": "Click on the Helpdesk button in main menu and connect with us for any kind of questions, feedback or technical problems. We will gladly assist you."
      },
      {
        "que": "On which devices can I use TractorBazar?",
        "ans": "TractorBazar app can be used from a smartphone, tablet or a desktop computer."
      },
      {
        "que": "How do I access the TractorBazar mobile app? / How do I log in on TractorBazar app?",
        "ans": "TractorBazar can be accessed from TAFE connect/ Eicher connect apps. First, login into the TAFE connect/ Eicher connect app using your 10 digit mobile number and 4 digit passcode. On TAFE connect/ Eicher connect home page, you can click on the TractorBazar logo to open the app; alternatively, the link to open the app is also available in the menu options on TAFE/ Eicher connect."
      },
      {
        "que": "How do I access the TractorBazar website?",
        "ans": "Simply go to TractorBazar.com in your web browser."
      },
      {
        "que": "Is my device supported?",
        "ans": "Application will support all mobile devices (smartphones) and Personal Computers"
      },
      {
        "que": "What browser is supported by Tractor Bazar?",
        "ans": "All versions starting Chrome Version 74.0.3729.157 and Firefox Version 66.05 is supported by the browser"
      },
      {
        "que": "How do I change the language settings?",
        "ans": "You can change the language from the “My profile” page."
      }
    ]
  }
];
