import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../services/authentication.service';
import { HeaderService } from '../../services/header.service';


interface countResponseI {
  data: {
    fav_count?: number
    notif_count: number
  },
  status: number
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isCollapsed = true;
  @Input() wantMassSms: boolean = false;
  @Input() sms;
  massSmsBox: boolean = false;
  smsArray: string;
  unread_ntfctn_cnt: number;
  constructor(public sanitizer: DomSanitizer,
    private router: Router,
    private translate: TranslateService,
    private auth: AuthenticationService,
    private headerService: HeaderService) {
    this.translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.headerService.unreadNtfctnCntChange.subscribe(res => {
      this.refreshCount();
    });
  }

  refreshCount() {
    this.headerService.getNotificationCount().subscribe((res: countResponseI) => {
      this.unread_ntfctn_cnt = res.data.notif_count;
    })
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  openMassSms() {
    this.massSmsBox = !this.massSmsBox;
  }

  openRoute(route) {
    this.router.navigate([`/${route}`]);
    this.toggleCollapse();
  }
  sanitize() {
    this.smsArray = `sms:${this.sms}`;
    return this.sanitizer.bypassSecurityTrustUrl(this.smsArray);
  }

  logoutfnc() {
    this.auth.logout();
  }

  toDashboard() {
    this.router.navigate(['/dashboard']);
  }
}
