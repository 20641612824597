import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const queryParams = route.queryParams
    const currentUser = this.authenticationService.currentUser
    if (this.authenticationService.isAuthenticated()) {
      if (route.url[0].path == "dashboard" && currentUser && currentUser.redirectTrigger) {
        return this.router.navigate(['/additional-details']);

      }
      if (route.url[0].path == "additional-details" && currentUser && !currentUser.redirectTrigger) {
        return this.router.navigate(['/dashboard']);

      }
      return true
    } else {
      if (queryParams.token && queryParams.secret_key) {
        this.authenticationService.setThirdPartyUser({
          token: queryParams.token,
          secret: queryParams.secret_key
        })
        return true;
      } else {
        if (this.authenticationService.thirdPartyUser) {
          return true
        } else {
          return this.router.navigate(['/invalid-url']);

        }
      }
    }

  }
}

