import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ContactUsService } from '../services/contact-us.service';

import { Router } from '@angular/router';
import { SnackbarService } from '../shared/snackbar/snackbar.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dealer-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactUsData: any;

  randomNum: number;
  submitted: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(private contactUsservice: ContactUsService, private router: Router,
     private messageService: SnackbarService, private translate: TranslateService) { }

  ngOnInit() {
    this.contactUsData = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
      contact_number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}')]),
      message: new FormControl('', Validators.required),
      wordMatch: new FormControl('', [Validators.required, this.matchNum.bind(this)]),
    });
    this.refreshNumber();
  }


  refreshNumber() {
    this.randomNum = Math.floor(1000 + Math.random() * 9000);
  }

  matchNum(control: AbstractControl) {
    if (control.value == this.randomNum) {
      return null;
    }
    else {
      return { validMatch: true };
    }
  }

  send() {
    this.submitted = true;
    if (this.contactUsData.valid) {
      let body = this.contactUsData.value;
      delete body.wordMatch;
     let sub =  this.contactUsservice.putContactUs(body).subscribe(res => {

        this.messageService.show(this.translate.instant('popup-msg.success'), this.translate.instant('popup-msg.contact-us'));

        // this.messageService.show('Success', 'Thank you for contacting us!');
        this.router.navigate(['/dashboard']);
      },
        error => { });
        this.subscription.add(sub);
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
  }
}
