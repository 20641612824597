import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule, ActionReducerMap } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";

import { inventoryReducer, InventoryState } from "./reducers/inventory.reducer";
import { archiveListReducer, inventoryListReducer, InventoryListState } from "./reducers/inventory-list.reducer";
import { stateDistrictReducer, StateDistrictState } from './reducers/state-district.reducer';

import { leadsListReducer, LeadsListState } from './reducers/leads-list.reducer';
import { DatabaseListState, databaseListReducer } from './reducers/database-list.reducer'


export interface AppState {
  inventoryData: InventoryState,
  invSortFilter: InventoryListState,
  stateDistrict: StateDistrictState,
  LeadsAddSortFilter: LeadsListState,
  databaseSortFilter: DatabaseListState

}

export const reducers: ActionReducerMap<AppState> = {
  inventoryData: inventoryReducer,
  invSortFilter: inventoryListReducer,
  stateDistrict: stateDistrictReducer,
  LeadsAddSortFilter: leadsListReducer,
  databaseSortFilter: databaseListReducer
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({}),
  ]
})
export class DealerStoreModule { }
