import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdditionalDetailsComponent } from "./additional-details/additional-details.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FaqComponent } from './faq/faq.component';
import { AuthGuard } from './guards/auth.guard';
import { InvalidUrlComponent } from './invalid-url/invalid-url.component';
import { TokenResolver } from './resolvers/token-resolver'
import { MyProfileComponent } from './my-profile/my-profile.component';
import { HelplineComponent } from './helpline/helpline.component';
import { TermsNConditionComponent } from './terms-n-condition/terms-n-condition.component';
import { ErrorMsgComponent } from './shared/error-msg/error-msg.component';
import { RecommendationsComponent } from './recommendations/recommendations.component';

import { BuyerViewComponent } from './buyer-view/buyer-view.component';
import {NotificationsListComponent} from './notifications-list/notifications-list.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    resolve: {
      team: TokenResolver
    }
  },
  {
    path: 'additional-details',
    component: AdditionalDetailsComponent,
    // canActivate: [AuthGuard],
    // resolve: {
    //   team: TokenResolver
    // }
  },
  {
    path: 'notifications',
    component: NotificationsListComponent,
    canActivate: [AuthGuard],
    resolve: {
      team: TokenResolver
    }
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'terms-n-condition',
    component: TermsNConditionComponent
  },
  {
    path: 'my-profile',
    component: MyProfileComponent
  },
  {
    path: 'buyer-view',
    component: BuyerViewComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'helpline',
    component: HelplineComponent
  },
  {
    path: 'recommendations',
    component: RecommendationsComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    resolve: {
      team: TokenResolver
    }
  },

  {
    path: 'invalid-url',
    component: InvalidUrlComponent
  },
  {
    path: 'sales-dashboard',
    loadChildren: () => import('./sales-dashboard/sales-dashboard.module').then(m => m.SalesDashboardModule)
  },
  // {
  //   path: 'sales-dashboard',
  //   loadChildren: () => import('./analytics-dashboard/analytics-dashboard.module').then(m => m.AnalyticsDashboardModule)
  // },
  {
    path: 'inventory-upload',
    loadChildren: () => import('./inventory-upload/inventory-upload.module').then(m => m.InventoryUploadModule)
  },
  {
    path: 'inventory',
    loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
    // canActivate: [AuthGuard],
    // resolve: {
    //   team: TokenResolver
    // }
  },
  {
    path: 'leads',
    loadChildren: () => import('./leads/leads.module').then(m => m.LeadsModule)
  },
  {
    path: 'finance-leads',
    loadChildren: () => import('./finance-leads/finance-lead.module').then(m => m.FinanceLeadModule)
  },
  {
    path: 'database',
    loadChildren: () => import('./database/database.module').then(m => m.DatabaseModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'error/:errorId',
    component: ErrorMsgComponent
  },
  {
    path: "**",
    redirectTo: 'dashboard'
  }
  // ,
  // {
  //   path: 'dealer',
  //   loadChildren: () => import('./dealer/dealer-routing.module').then(m => m.DealerRoutingModule)
  // },
  // {
  //   path: 'language-select',
  //   component: LanguageSelectComponent
  // }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [TokenResolver]
})
export class AppRoutingModule { }
