import { Injectable } from "@angular/core";
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "../services/authentication.service";

import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, of } from "rxjs";

import { map, catchError } from "rxjs/operators";
import { environment } from '../../environments/environment';
import { DealerService } from '../services/dealer.service';

export interface Loginresponse {
  data: {
    refresh: string
    access: string
    additional_detail_trigger: boolean
    dealer_id: any
    edsp_id: any
    dealer_state: number
    dealer_district: number
    unread_notif_count: number
    user_id: any
    user_type:any
    admin_id:any
    state_mapped:any
    dealers_mapped:any
    Name:any

  }
}

@Injectable()
export class TokenResolver implements Resolve<{}> {
  private endpoint = environment.api;
  constructor(private authenticationService: AuthenticationService,
    private http: HttpClient, private router: Router, private dealerService: DealerService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    if (this.authenticationService.isAuthenticated()) {
      //is expired code can go here
      return true;
    }
    const thirdPartyUser = this.authenticationService.thirdPartyUser
    if (thirdPartyUser) {
      let headers = new HttpHeaders();
      const payload = new FormData();
      payload.set('secret_key', thirdPartyUser.secret);

      headers = headers.set('Authorization', thirdPartyUser.token);
      // headers.append('Access-Control-Allow-Credentials', 'true');
      return this.http.post<Loginresponse>(`${this.endpoint}/user/login`, payload, { headers: headers })
        .pipe(map(response => {
          const user = response.data;
          console.log('Response data',user);
          this.dealerService.dealer_id = response.data['dealer_id'];
          // login successful if there's a jwt token in the response
          this.authenticationService.currentUser = {
            token: user.access,
            redirectTrigger: user.additional_detail_trigger, dealer_id: user.dealer_id, refresh: user.refresh, edsp_id: user.edsp_id
          }

          // store user details and jwt token in local storage to keep user logged in between page refreshes            

          if (user && user.access) {
            if(user.user_type == 'dealer'){
              localStorage.setItem('token', JSON.stringify(user.access));
              localStorage.setItem('redirectTrigger', JSON.stringify(user.additional_detail_trigger));
              localStorage.setItem('dealer_Id', JSON.stringify(user.dealer_id));
              localStorage.setItem('edsp_id', JSON.stringify(user.edsp_id))
              localStorage.setItem('refresh', JSON.stringify(user.refresh));
              localStorage.setItem('district_id', JSON.stringify(user.dealer_district));
              localStorage.setItem('state_id', JSON.stringify(user.dealer_state));
              localStorage.setItem('user_id', JSON.stringify(user.user_id));
              localStorage.setItem('user_type',JSON.stringify(user.user_type))
            }
            else if(user.user_type == 'TETM' || user.user_type == 'HO'){
              sessionStorage.setItem('token', JSON.stringify(user.access));
              sessionStorage.setItem('redirectTrigger', JSON.stringify(user.additional_detail_trigger));
              sessionStorage.setItem('refresh', JSON.stringify(user.refresh));

              sessionStorage.setItem('dealer_Id', JSON.stringify('Dummy'));
              sessionStorage.setItem('edsp_id', JSON.stringify('Dummy'))
              localStorage.setItem('user_type',JSON.stringify(user.user_type))

              sessionStorage.setItem('admin_id',JSON.stringify(user.admin_id))
              sessionStorage.setItem('dealers_mapped',JSON.stringify(user.dealers_mapped))
              sessionStorage.setItem('state_mapped',JSON.stringify(user.state_mapped))
              sessionStorage.setItem('name',JSON.stringify(user.Name))
              this.router.navigate(['/sales-dashboard'])
            }        
            //TODO don't redirect if path is already additional-details
            if (user.additional_detail_trigger) {
              this.router.navigate(['/additional-details']);
            }

          }
          return user
        }), catchError(error => {
          //   console.log(`Error fetching auth token ${error.message}`);
          this.router.navigate(['/invalid-url']);
          return of(null);
        }));
    } else {
      //console.log(`No token and secret key in url`);
      this.router.navigate(['/invalid-url']);
      return of(null);
    }
  }
}
