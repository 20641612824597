import { Action } from "@ngrx/store";

export enum InventoryListActionTypes {
    ADD_SORT_FILTER = '[INVENTORY] Add Sort Filter',
    REMOVE_SORT_FILTER = '[INVENTORY] Remove Sort Filter',
    ADD_ARCH_SORT_FILTER = '[ARCHIVE] Add Archive Sort Filter',
    REMOVE_ARCH_SORT_FILTER = '[ARCHIVE] Remove Archive Sort Filter'
}

export class AddSortFilter implements Action {
    readonly type = InventoryListActionTypes.ADD_SORT_FILTER;
    readonly typeArch = InventoryListActionTypes.ADD_ARCH_SORT_FILTER;
   
    constructor(public payload: any, public list: any) { 
        if(list=='archList'){payload = this.typeArch}
        if(list=='invList'){payload = this.type}
    }
}

export class RemoveSortFilter implements Action {
  
    readonly type = InventoryListActionTypes.REMOVE_SORT_FILTER;
    readonly typeArch = InventoryListActionTypes.REMOVE_ARCH_SORT_FILTER;
    constructor() { }
}

export type Action = RemoveSortFilter | AddSortFilter;