import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-menu-box',
  templateUrl: './menu-box.component.html',
  styleUrls: ['./menu-box.component.scss']
})

// tslint:disable-next-line:prefer-const
// let a: any;
export class MenuBoxComponent implements OnInit {

  // tslint:disable-next-line:ban-types
  @Input() url: any;
  @Input() style: any;
  @Input() id: any;
  @Output() select = new EventEmitter<any>();


  selectMenu(event: object) {

    this.select.emit(event);
  }
  aa = (a: any) => {
    // console.log(a);
  }

  constructor() {
  }

  ngOnInit() {
    //console.log(this.id)
  }

}
