import { Action, InventoryActionTypes } from "../actions/inventory.action";

export interface InventoryState {
  inventoryData: any[];
  inventory_id: number
}

const initialState: InventoryState = {
  inventoryData: null,
  inventory_id: null
};

export function inventoryReducer(state = initialState, action: Action) {
  switch (action.type) {
    case InventoryActionTypes.ADD_INVENTORY:
      return { ...state, inventoryData: action.payload };

    case InventoryActionTypes.REMOVE_INVENTORY:
      return { ...state, inventoryData: null, inventory_id: null };



    case InventoryActionTypes.UPDATE_INVENTORY:
      const inventory = state.inventoryData[action.payload.index]
      const updateInventory = {
        ...inventory,
        ...action.payload.data
      };
      const updatedInventory = [...state.inventoryData];
      updatedInventory[action.payload.index] = updateInventory;
      return {
        ...state,
        inventoryData: updatedInventory
      };

    case InventoryActionTypes.ADD_INVVENTORY_ID:
      return { ...state, inventory_id: action.payload };

    case InventoryActionTypes.REMOVE_INVENTORY_ID:
      state.inventory_id = null;
      return { ...state };
    default: return state;
  }
}

