import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  @Input() summary: any;
  @Input() periodic: any;
  constructor() { }

  ngOnInit() { }

  ngOnChanges() {
    if (this.periodic != null) {
  //    this.periodic['title'] = this.periodic['title'].replace(/ /g, "-").toLowerCase();
    }
  }
}
