import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// components
import { AdditionalDetailsComponent } from './additional-details/additional-details.component';
import { SummaryComponent } from './summary/summary.component';
import { DashboardComponent } from './dashboard/dashboard.component';

// modules
// @ts-ignore
import { HttpModule } from '@angular/http';
import { SharedModule } from './shared/shared.module';
// import { PushServiceService } from './push-service.service';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './interceptor';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DealerStoreModule } from './store/dealer-store.module';
import { AboutUsComponent } from './about-us/about-us.component';
import { FaqComponent } from './faq/faq.component';
import { CommonModule } from '@angular/common';
import { NgxImageCompressService } from 'ngx-image-compress';
import { InvalidUrlComponent } from './invalid-url/invalid-url.component';

import { MyProfileComponent } from './my-profile/my-profile.component';
import { HelplineComponent } from './helpline/helpline.component';
import { TermsNConditionComponent } from './terms-n-condition/terms-n-condition.component';
import { RecommendationsComponent } from './recommendations/recommendations.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BuyerViewComponent } from './buyer-view/buyer-view.component';
import { BuyerViewBoxComponent } from './buyer-view-box/buyer-view-box.component';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';

@NgModule({
  declarations: [
    AppComponent,
    AdditionalDetailsComponent,
    DashboardComponent,
    SummaryComponent,
    ContactUsComponent,
    AboutUsComponent,
    FaqComponent,
    InvalidUrlComponent,
    MyProfileComponent,
    HelplineComponent,
    TermsNConditionComponent,
    RecommendationsComponent,
    BuyerViewComponent,
    BuyerViewBoxComponent,
    NotificationsListComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    // ngx-translate and the loader module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    SharedModule,
    DealerStoreModule,
    HttpModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ServiceWorkerModule.register('sw-master.js', { enabled: environment.production }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule

  ],
  providers:
    [{ provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      NgxImageCompressService
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
