import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-summary-container',
  templateUrl: './summary-container.component.html',
  styleUrls: ['./summary-container.component.scss']
})
export class SummaryContainerComponent implements OnInit {
@Input() item:any;
  constructor() { }

  ngOnInit() {
  }

}
